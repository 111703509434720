'use client';
import Link from "next/link";

function handleOpenSearch(){
  // GTMSendEvent({
  //   event: "select_content",
  //   eventAction: "click",
  //   content_type: "Button",
  //   content_name: "Menu",
  //   section: '/buscador',
  // })
}

export default function SearchButton(){
  return (
    <Link href="/buscador" passHref id="btn_search" title="Búsqueda" onClick={handleOpenSearch} prefetch={false}>
			<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icons" clipPath="url(#clip0_5335_577)">
          <path id="Vector" d="M23.25 21H22.065L21.645 20.595C23.115 18.885 24 16.665 24 14.25C24 8.865 19.635 4.5 14.25 4.5C8.865 4.5 4.5 8.865 4.5 14.25C4.5 19.635 8.865 24 14.25 24C16.665 24 18.885 23.115 20.595 21.645L21 22.065V23.25L28.5 30.735L30.735 28.5L23.25 21ZM14.25 21C10.515 21 7.5 17.985 7.5 14.25C7.5 10.515 10.515 7.5 14.25 7.5C17.985 7.5 21 10.515 21 14.25C21 17.985 17.985 21 14.25 21Z" fill="#F1EFE3"/>
        </g>
        <defs>
          <clipPath id="clip0_5335_577">
            <rect width="36" height="36" fill="white"/>
          </clipPath>
        </defs>
      </svg>
		</Link>
  );
}