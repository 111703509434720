"use client";

import { useEffect, useState } from "react";

const PushNotification = () => {
	const [swInstalled, setSWInstalled] = useState(false);

	import(`@/app/site-tools/web-notifications/WebNotificationScript`).then(
		(js) => js.RenderWebNotificationScript()
	);

	useEffect(() => {
		if ("serviceWorker" in navigator) {
			// eslint-disable-next-line no-unused-vars
			navigator.serviceWorker.register("/service-worker.js").then(
				() => {
					setSWInstalled(true);
				},
				(err) => {
					console.error(err);
				}
			);
		}
	}, []);

	useEffect(() => {
		if (swInstalled) {
			// eslint-disable-next-line no-unused-vars
			let deferredPrompt;
			window.addEventListener("beforeinstallprompt", (e) => {
				e.preventDefault();
				deferredPrompt = e;
				// Handle how to show prompt to user
			});
		}
	}, [swInstalled]);

	return null;
};

export default PushNotification;
