import styles from '../../styles/Buttons/ButtonCloseMenu.module.scss'

export default function CloseHamburgerButton({handleClick}: any){
  return (
    <button type="button" onClick={handleClick} className={styles?.btn_close_menu} title="Cerrar menú">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#ffffff">
        <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
      </svg>
    </button>
  );
}